import 'what-input';
import LazyLoad from "vanilla-lazyload";

import '../styles/main.scss';

import Home from './pages/home';

new LazyLoad();

document.getElementById('scrolldown').addEventListener('click', () => {
	window.scrollTo({
		top: document.body.scrollHeight,
		behavior: 'smooth'
	});
});

document.getElementById('scrollup').addEventListener('click', () => {
	window.scrollTo({
		top: 0,
		behavior: 'smooth'
	});
});

const mainEl = document.querySelector("main");
if (mainEl.classList.contains("home")) {
	new Home();
}