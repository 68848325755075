import mixitup from 'mixitup';
import Swiper, { Navigation } from 'swiper';

// Module parse failed: Unexpected character '@'
// import 'swiper/css';
// import 'swiper/css/navigation';

export default class Home {
	constructor() {
		this.init();
	}

	init() {
		const galleryEl = document.getElementById('gallery');
		const swiper = new Swiper(galleryEl.querySelector('.swiper-container'), {
			modules: [Navigation],
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			grabCursor: true,
		});

		const imageLis = document.querySelectorAll('#images > ul > li');
		imageLis.forEach((child, i) => {
			child.setAttribute('data-index', i);
			child.addEventListener('click', () => {
				let index = child.getAttribute('data-index');
				galleryEl.classList.add('shown');
				swiper.slideTo(index, 0);
			});
		});

		galleryEl.querySelector('.close').addEventListener('click', () => {
			galleryEl.classList.remove('shown');
		});

		const mixer = mixitup('.portfolio', {
			selectors: {
				target: '.mix'
			},
			animation: {
				duration: 700,
				effects: 'fade translateY(600%) stagger(35ms)',
				easing: 'cubic-bezier(0.86, 0, 0.07, 1)',
				reverseOut: true
			},
			callbacks: {
				onMixEnd: (state) => {
					state.show.forEach((child, i) => {
						child.classList.remove('hidden');
						child.setAttribute('data-index', i);
						
						let index = [...child.parentNode.children].indexOf(child);
						const slide = galleryEl.querySelectorAll('.slide')[index];
						slide.classList.remove('hidden');
					});
					state.hide.forEach((child, i) => {
						child.classList.add('hidden');
						
						let index = [...child.parentNode.children].indexOf(child);
						const slide = galleryEl.querySelectorAll('.slide')[index];
						slide.classList.add('hidden');
					});
					swiper.update();
				}
			}
		});
	}

}